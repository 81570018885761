<template>
  <div class="movers-content">
    <div class="container" v-if="! loading">
      <div class="search-container">
        <h1 class="text-center">Reliable Moving Partners</h1>
        <form @submit.prevent="fetch">
          <div class="input-group">
            <v-select placeholder="Select Region" :options="regions" label="name" v-model="selected_region" />
            <span class="input-group-addon">
              <button><i class="lni lni-search"></i></button>
            </span>
          </div>
        </form>
      </div>
      <div class="movers-list">
        <div class="mover" v-for="(mover, i) in movers" :key="`mover-${i}`">
          <span class="sponsored" v-if="mover.promoted">Sponsored</span>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="image">
                    <router-link :to="{ name: 'movers.show', params: { id: mover.slug }}"
                      ><img class="logo" :src="mover.logo" alt="logo"
                    /></router-link>
                  </div>
                </div>
                <div class="col-md-9">
                  <h3><router-link :to="{ name: 'movers.show', params: { id: mover.slug }}">{{ mover.name }}</router-link></h3>
                  <div class="regions">
                    <a href="#" v-for="(region, j) in mover.regions" :key="`mover-${i}-${j}`" style="margin-right: 10px">{{ region }}</a>
                  </div>
                  <rating />
                  <div class="description">{{ mover.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="movers.length == 0">
          <alert type="info">
            We have found no movers here.
          </alert>
        </div>
      </div>

      <div>
        <pagination
          v-model="pagination.page"
          :records="pagination.records"
          @paginate="fetch"
          :perPage="10"
        />
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      pagination: { page: 1, records: 1 },
      loading: true,
      movers: [],
      regions: [],
      selected_region: null
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/movers?page=${this.pagination.page}&region=${this.selected_region ? this.selected_region.id : ''}`).then(response => {
        this.movers = response.data.movers
        this.pagination = response.data.pagination
        this.regions = response.data.regions
        this.loading = false
      })
    },
  },

  watch: {
    selected_region() {
      this.fetch()
    }
  },
};
</script>

<style lang="scss">
.movers-content {
  border-top: solid 1px #eee;
  background: rgba(#f8f8f8, 0.4);

  .search-container {
    padding: 5rem 0;
    width: 60%;
    margin: 0 auto;

    .v-select {
      height: 100%;
      background: #fff;
    }

    .vs__dropdown-toggle {
      margin-bottom: 0;
      border-right: none;
      padding: 0;
      height: 100%;

      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: #ddd;
      }
    }

    .vs__actions {
      .vs__open-indicator {
        display: none;
      }
    }

    .input-group-addon {
      padding: 0;
      background: #fff;

      button {
        border: none;
        display: block;
        height: 100%;
        padding: 0 1rem;
        background: none;
      }
    }
  }

  .movers-list {
    .mover {
      .sponsored {
        float: right;
        background: #3d0859;
        color: #fff;
        padding: 5px 10px;
      }

      .card {
        padding-bottom: 30px;

        .card-body {
          a {
            color: #3d0859;
          }

          .image {
            img.logo {
              max-width: 100%;
            }
          }

          .regions {
            margin-bottom: 10px;
          }

          .description {
            margin-top: 10px;
          }

          .contacts {
            margin-top: 1rem;

            a {
              font-size: 16px;
              margin-right: 15px;

              i {
                font-size: 14px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
