<template>
  <div class="layout-theme animated-css theme-2">
    <div id="wrapper">
      <div class="main">
        <header class="header">
          <div class="header__top">
            <top-bar />
          </div>
          <!-- end header__top .-->
          <div class="container">
            <div class="row">
              <div class="col-xs-12 header-nav">
                <div class="header__logo">
                  <a href="/movers" class="logo">
                    <img src="/logo.png" alt="Logo" height="60px" />
                    <span>Movers</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <!-- end header .-->
        
        <router-view @loaded="loaded" />

        <services-contacts style="margin-top: 100px;" />

        <footer class="footer">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <h3 class="ui-title-inner">ABOUT REALHUB MOVERS</h3>
                <div class="border-color"></div>
                <div v-html="about"></div>
                <news-letter />
              </div>
              <div class="col-md-4">
                <h3 class="ui-title-inner">CATEGORIES</h3>
                <div class="border-color"></div>
                <ul class="list-mark">
                  <li class="list-mark__item" v-for="(category, i) in categories" :key="`category-${i}`">
                    <router-link :to="{ name: 'page', params: { slug: category.slug }}" class="list-mark__link"
                      ><span class="icon"></span>{{ category.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <h3 class="ui-title-inner">CONTACT US</h3>
                <div class="border-color"></div>
                <div class="footer__title-block">Office Address</div>
                <p>{{ contact.location }}</p>
                <div class="footer__title-block">Phone Number</div>
                <p>{{ contact.phone_number }}</p>
                <div class="footer__title-block">Email</div>
                <p>
                  <a :href="`mailto:${contact.email}`" class="footer__mail"
                    >{{ contact.email }}</a
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="copyright">
                <span>© Copyrights 2023<strong> REALHUB.</strong></span
                ><span> All Rights Reserved.</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import '@/theme-2.js'
import TopBar from './components/TopBar.vue'

export default {
  components: {
    TopBar
  },

  mounted() {
    document.title = 'Realhub Kenya: The Reliable Movers for a Hassle-Free Relocation Experience.'
  },

  data() {
    return {
      about: ''
    }
  },

  methods: {
    loaded(about) {
      this.about = about
    }
  },

  computed: {
    contact() {
      return  this.$store.getters.getContact
    },

    categories() {
      return this.$store.getters.getMenuCategories;
    },
  }
}
</script>